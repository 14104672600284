import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { API_GET_CAM_DATA, API_POST_CAM_DATA } from '../../../../utils/APIUrls';
import { GET, POST } from '../../../../utils/webAPI.service';
import { loaderModal } from '../../../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Cam extends Component {
  state = {
    sellerRelationShip: {},
    turnoverAnalysis: {},
    creditBehaviour: {},
    limitInfo: {},
    stabilityProfile: [{}],
    assetsLiabilitiesProfile: {},
    neighbourReference: [{}],
    supplierReference: [{}],
    businessDetails: {},
    customerType: null,
    promoterAge: null,
    dedupeResult: null,
    remarks: null,
  };

  inputHandler = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  stabilityProfileHandler = (e, index) => {
    let { name, value } = e.target;
    let { stabilityProfile } = this.state,
      stabilityProfileCopy = [...stabilityProfile];
    stabilityProfileCopy[index] = {
      ...stabilityProfileCopy?.[index],
      [name]: value,
    };
    stabilityProfile = stabilityProfileCopy;
    this.setState({ stabilityProfile });
  };

  spaceHandler = (e, index, name) => {
    let { value } = e.target;

    let { stabilityProfile } = this.state,
      stabilityProfileCopy = [...stabilityProfile];
    if (value === 'OWNED') {
      stabilityProfileCopy[index] = {
        ...stabilityProfileCopy?.[index],
        [name]: value,
        periodOfStay: stabilityProfileCopy?.[index].periodOfStay ?? '',
        monthlyRent: stabilityProfileCopy?.[index].monthlyRent ?? '',
      };
    } else if (value === 'RENTED') {
      stabilityProfileCopy[index] = {
        ...stabilityProfileCopy[index],
        [name]: value,
        duration: stabilityProfileCopy?.[index].duration ?? '',
        purchaseAmount: stabilityProfileCopy?.[index].purchaseAmount ?? '',
        yearOfPurchase: stabilityProfileCopy?.[index].yearOfPurchase ?? '',
        ownershipType: stabilityProfileCopy?.[index].ownershipType ?? null,
      };
    }

    stabilityProfile = stabilityProfileCopy;
    this.setState({ stabilityProfile });
  };

  neighbourReferenceHandler = (e, index) => {
    let { name, value } = e.target;
    let { neighbourReference } = this.state,
      neighbourReferenceCopy = [...neighbourReference];
    neighbourReferenceCopy[index] = {
      ...neighbourReferenceCopy?.[index],
      [name]: value,
    };
    neighbourReference = neighbourReferenceCopy;
    this.setState({ neighbourReference });
  };

  supplierReferenceHandler = (e, index) => {
    let { name, value } = e.target;
    let { supplierReference } = this.state,
      supplierReferenceCopy = [...supplierReference];
    supplierReferenceCopy[index] = {
      ...supplierReferenceCopy?.[index],
      [name]: value,
    };
    supplierReference = supplierReferenceCopy;
    this.setState({ supplierReference });
  };

  submitCam = (e, submitted) => {
    e.preventDefault();
    let { match } = this.props,
      { userId } = match.params,
      {
        sellerRelationShip,
        turnoverAnalysis,
        creditBehaviour,
        limitInfo,
        stabilityProfile,
        assetsLiabilitiesProfile,
        neighbourReference,
        supplierReference,
        businessDetails,
        customerType,
        promoterAge,
        dedupeResult,
        remarks,
      } = this.state,
      data = {
        sellerRelationShip,
        turnoverAnalysis,
        creditBehaviour,
        limitInfo,
        stabilityProfile: [
          { ...stabilityProfile?.[0], residenceType: 'RESIDENTIAL' },
          { ...stabilityProfile?.[1], residenceType: 'BUSINESS' },
        ],
        assetsLiabilitiesProfile,
        neighbourReference,
        supplierReference,
        businessDetails,
        customerType,
        promoterAge,
        dedupeResult,
        remarks,
        submitted,
        userId,
      };

    POST(API_POST_CAM_DATA, data)
      .then(({ data }) => {
        if (data.code === 200) {
          alert('Data Saved Successfully');
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  render() {
    let { match } = this.props,
      { userId } = match.params;

    let {
      sellerRelationShip,
      turnoverAnalysis,
      creditBehaviour,
      limitInfo,
      stabilityProfile,
      assetsLiabilitiesProfile,
      neighbourReference,
      supplierReference,
      businessDetails,
      remarks,
      customerType,
      promoterAge,
      dedupeResult,
      submitted,
    } = this.state;
    let creditBehaviourExit =
      Object.keys(creditBehaviour ?? {})?.length > 0 || false;

    return (
      <div className='cam_wrapper  container'>
        <Helmet>
          <meta charSet='utf-8' />
          <title> Cam Acquisition</title>
        </Helmet>
        <form onSubmit={(e) => this.submitCam(e, true)}>
          <div className='card'>
            <div className='text-grey mb-1'>User Id</div>
            <h3 className=' card_heading_secondary  m-heading my-0'>
              {userId}
            </h3>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Type of customer
                  <select
                    value={customerType}
                    onChange={(e) =>
                      this.setState({
                        customerType: e.target.value,
                      })
                    }
                  >
                    <option value=''>Select Type of customer</option>
                    <option value={'GST_WITH_BUREAU_THICK'}>
                      GST WITH BUREAU THICK
                    </option>
                    <option value={'GST_WITH_BUREAU_THIN'}>
                      GST WITH BUREAU THIN
                    </option>
                    <option value={'NON_GST_WITH_BUREAU_THICK'}>
                      NON-GST WITH BUREAU THICK
                    </option>
                    <option value={'FLDG'}>FLDG</option>
                  </select>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Promoter Age
                  <input
                    placeholder='Age'
                    type='text'
                    required
                    value={promoterAge}
                    onChange={(e) =>
                      this.setState({
                        promoterAge: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Dedupe Result
                  <select
                    value={dedupeResult}
                    onChange={(e) =>
                      this.setState({
                        dedupeResult: e.target.value,
                      })
                    }
                  >
                    <option value=''>Select Dedupe Result</option>
                    <option value={true}>Pass</option>
                    <option value={false}>Fail</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary m-heading'>
              Business Details
            </h3>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Turnover Last Year
                  <div className='input_boxes'>
                    <input
                      placeholder='Yr'
                      type='number'
                      required
                      className='w-20 mr-3'
                      value={businessDetails?.lastYear}
                      onChange={(e) =>
                        this.setState({
                          businessDetails: {
                            ...businessDetails,
                            lastYear: e.target.value,
                          },
                        })
                      }
                    />
                    <input
                      placeholder='Turnover in ₹ Amount '
                      type='number'
                      required
                      className='w-70'
                      value={businessDetails?.turnoverLastYear}
                      onChange={(e) =>
                        this.setState({
                          businessDetails: {
                            ...businessDetails,
                            turnoverLastYear: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Turnover Year Before
                  <div className='input_boxes'>
                    <input
                      placeholder='Yr '
                      type='number'
                      required
                      className='w-20 mr-3'
                      value={businessDetails?.yearBefore}
                      onChange={(e) =>
                        this.setState({
                          businessDetails: {
                            ...businessDetails,
                            yearBefore: e.target.value,
                          },
                        })
                      }
                    />
                    <input
                      placeholder='Turnover in ₹ Amount '
                      type='number'
                      required
                      className='w-70'
                      value={businessDetails?.turnoverYearBefore}
                      onChange={(e) =>
                        this.setState({
                          businessDetails: {
                            ...businessDetails,
                            turnoverYearBefore: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Business Start
                  <select
                    disabled={true}
                    value={businessDetails?.businessStart}
                    onChange={(e) =>
                      this.setState({
                        businessDetails: {
                          ...businessDetails,
                          businessStart: e.target.value,
                        },
                      })
                    }
                  >
                    <option value=''>Select Business Start</option>
                    <option value='SELF'>Self started</option>
                    <option value='INHERITED'>Inherited</option>
                  </select>
                  <div className='limit-parameters'>
                    Self started or Inherited
                  </div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Customer Category
                  <select
                    disabled={true}
                    value={businessDetails?.customerCategory}
                    onChange={(e) =>
                      this.setState({
                        businessDetails: {
                          ...businessDetails,
                          customerCategory: e.target.value,
                        },
                      })
                    }
                  >
                    <option value=''>Select Customer Category</option>
                    <option value='GST'>GST</option>
                    <option value='NON_GST'>NON-GST</option>
                  </select>
                  <div className='limit-parameters'>GST or Non GST</div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Net Margin %
                  <input
                    placeholder='Net % '
                    type='number'
                    required
                    value={businessDetails?.netMarginPercent}
                    onChange={(e) =>
                      this.setState({
                        businessDetails: {
                          ...businessDetails,
                          netMarginPercent: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>Computed</div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Profit Last Year
                  <div className='input_boxes'>
                    <input
                      placeholder='Yr '
                      type='number'
                      required
                      className='w-20 mr-3'
                      value={businessDetails?.lastYear}
                      onChange={(e) =>
                        this.setState({
                          businessDetails: {
                            ...businessDetails,
                            lastYear: e.target.value,
                          },
                        })
                      }
                    />
                    <input
                      placeholder='Turnover in ₹ Amount '
                      type='number'
                      required
                      className='w-70'
                      value={businessDetails?.profitLastYear}
                      onChange={(e) =>
                        this.setState({
                          businessDetails: {
                            ...businessDetails,
                            profitLastYear: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className='limit-parameters'>Computed</div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Current year monthly run rate
                  <input
                    placeholder='Average amount per month'
                    type='number'
                    required
                    value={businessDetails?.monthlyRunRate}
                    onChange={(e) =>
                      this.setState({
                        businessDetails: {
                          ...businessDetails,
                          monthlyRunRate: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Other business or Income source
                  <select
                    disabled={true}
                    onChange={(e) =>
                      this.setState({
                        businessDetails: {
                          ...businessDetails,
                          otherIncomeSource: e.target.value,
                        },
                      })
                    }
                    value={businessDetails?.otherIncomeSource}
                  >
                    <option value=''>
                      Select Other business or Income source
                    </option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  <div className='limit-parameters'>In family</div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Number of Employees
                  <input
                    disabled={true}
                    placeholder='Count'
                    type='number'
                    required
                    value={businessDetails?.numberOfEmployees}
                    onChange={(e) =>
                      this.setState({
                        businessDetails: {
                          ...businessDetails,
                          numberOfEmployees: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>Off role and On role</div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Total Salary Paid per Month
                  <input
                    disabled={true}
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={businessDetails?.totalSalaryPaidPerMonth}
                    onChange={(e) =>
                      this.setState({
                        businessDetails: {
                          ...businessDetails,
                          totalSalaryPaidPerMonth: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>Monthly</div>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary m-heading '>
              Supplier Reference
            </h3>
            <div className='row'>
              <div className='col-md-6'>
                <div className='col-md-6 pl-4'>
                  <h5>Supplier Reference 1</h5>
                  <div className='text-black'>
                    Name of the supplier
                    <input
                      disabled={true}
                      placeholder='Supplier Name'
                      type='text'
                      required
                      name={'name'}
                      value={supplierReference?.[0]?.name}
                      onChange={(e) => this.supplierReferenceHandler(e, 0)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Purchase Amount in last 12 months
                    <input
                      placeholder='₹ Amount'
                      type='number'
                      required
                      name={'purchaseAmount'}
                      value={supplierReference?.[0]?.purchaseAmount}
                      onChange={(e) => this.supplierReferenceHandler(e, 0)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Duration of Business
                    <input
                      placeholder='months'
                      type='number'
                      required
                      name={'durationOfBusiness'}
                      value={supplierReference?.[0]?.durationOfBusiness}
                      onChange={(e) => this.supplierReferenceHandler(e, 0)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Delay in Payments
                    <select
                      name={'delayInPayments'}
                      value={supplierReference?.[0]?.delayInPayments}
                      onChange={(e) => this.supplierReferenceHandler(e, 0)}
                    >
                      <option value=''>Select Delay in Payments</option>
                      <option value='ALWAYS_ON_TIME'>Always on time </option>
                      <option value='LATE_UPTO_7_DAYS'>
                        {' '}
                        Late upto 7 days
                      </option>
                      <option value='LATE_UPTO_15_DAYS'>
                        Late upto 15 days
                      </option>
                      <option value='LATE_UPTO_30_DAYS'>
                        Late upto 30 days
                      </option>
                    </select>
                    <div className='limit-parameters'>
                      Always on time, Late upto 7 days, Late upto 15 days, Late
                      upto 30 days
                    </div>
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Any delay instance
                    <select
                      name={'delayInstance'}
                      value={supplierReference?.[0]?.delayInstance}
                      onChange={(e) => this.supplierReferenceHandler(e, 0)}
                    >
                      <option value=''>Select Any delay instance</option>
                      <option value={true}>Yes </option>
                      <option value={false}> No</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    General Conduct
                    <select
                      name={'generalConduct'}
                      value={supplierReference?.[0]?.generalConduct}
                      onChange={(e) => this.supplierReferenceHandler(e, 0)}
                    >
                      <option value=''>Select General Conduct</option>
                      <option value='ADVERSE'>Adverse </option>
                      <option value='SATISFACTORY'> Satisfactory</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='col-md-6 pl-4'>
                  <h5>Supplier Reference 2</h5>
                  <div className='text-black'>
                    Name of the supplier
                    <input
                      disabled={true}
                      placeholder='Supplier Name'
                      type='text'
                      required
                      name={'name'}
                      value={supplierReference?.[1]?.name}
                      onChange={(e) => this.supplierReferenceHandler(e, 1)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Purchase Amount in last 12 months
                    <input
                      placeholder='₹ Amount'
                      type='number'
                      required
                      name={'purchaseAmount'}
                      value={supplierReference?.[1]?.purchaseAmount}
                      onChange={(e) => this.supplierReferenceHandler(e, 1)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Duration of Business
                    <input
                      placeholder='months'
                      type='number'
                      required
                      name={'durationOfBusiness'}
                      value={supplierReference?.[1]?.durationOfBusiness}
                      onChange={(e) => this.supplierReferenceHandler(e, 1)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Delay in Payments
                    <select
                      name={'delayInPayments'}
                      value={supplierReference?.[1]?.delayInPayments}
                      onChange={(e) => this.supplierReferenceHandler(e, 1)}
                    >
                      <option value=''>Select Delay in Payments</option>
                      <option value='ALWAYS_ON_TIME'>Always on time </option>
                      <option value='LATE_UPTO_7_DAYS'>
                        {' '}
                        Late upto 7 days
                      </option>
                      <option value='LATE_UPTO_15_DAYS'>
                        Late upto 15 days
                      </option>
                      <option value='LATE_UPTO_30_DAYS'>
                        Late upto 30 days
                      </option>
                    </select>
                    <div className='limit-parameters'>
                      Always on time, Late upto 7 days, Late upto 15 days, Late
                      upto 30 days
                    </div>
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Any delay instance
                    <select
                      name={'delayInstance'}
                      value={supplierReference?.[1]?.delayInstance}
                      onChange={(e) => this.supplierReferenceHandler(e, 1)}
                    >
                      <option value=''>Select Any delay instance</option>
                      <option value={true}>Yes </option>
                      <option value={false}> No</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    General Conduct
                    <select
                      name={'generalConduct'}
                      value={supplierReference?.[1]?.generalConduct}
                      onChange={(e) => this.supplierReferenceHandler(e, 1)}
                    >
                      <option value=''>Select General Conduct</option>
                      <option value='ADVERSE'>Adverse </option>
                      <option value='SATISFACTORY'> Satisfactory</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary m-heading '>
              Neighbour Reference
            </h3>
            <div className='row'>
              <div className='col-md-6'>
                <div className='col-md-6 pl-4'>
                  <h5>Neighbour Reference 1</h5>
                  <div className='text-black'>
                    Name of the neighbour
                    <input
                      placeholder='Neighbour Name'
                      type='text'
                      required
                      name={'name'}
                      value={neighbourReference?.[0]?.name}
                      onChange={(e) => this.neighbourReferenceHandler(e, 0)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Duration of Business
                    <input
                      placeholder='months'
                      type='number'
                      required
                      name={'durationOfBusiness'}
                      value={neighbourReference?.[0]?.durationOfBusiness}
                      onChange={(e) => this.neighbourReferenceHandler(e, 0)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    General Conduct
                    <select
                      onChange={(e) => this.neighbourReferenceHandler(e, 0)}
                      value={neighbourReference?.[0]?.generalConduct}
                      name={'generalConduct'}
                    >
                      <option value=''>Select General Conduct</option>
                      <option value='ADVERSE'>Adverse </option>
                      <option value='SATISFACTORY'> Satisfactory</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='col-md-6 pl-4'>
                  <h5>Neighbour Reference 2</h5>
                  <div className='text-black'>
                    Name of the neighbour
                    <input
                      placeholder='Neighbour Name'
                      type='text'
                      required
                      name={'name'}
                      value={neighbourReference?.[1]?.name}
                      onChange={(e) => this.neighbourReferenceHandler(e, 1)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    Duration of Business
                    <input
                      placeholder='months'
                      type='number'
                      required
                      name={'durationOfBusiness'}
                      value={neighbourReference?.[1]?.durationOfBusiness}
                      onChange={(e) => this.neighbourReferenceHandler(e, 1)}
                    />
                  </div>
                </div>
                <div className='col-md-6 pl-4'>
                  <div className='text-black'>
                    General Conduct
                    <select
                      onChange={(e) => this.neighbourReferenceHandler(e, 1)}
                      value={neighbourReference?.[1]?.generalConduct}
                      name={'generalConduct'}
                    >
                      <option value=''>Select General Conduct</option>
                      <option value='ADVERSE'>Adverse </option>
                      <option value='SATISFACTORY'> Satisfactory</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary m-heading '>
              Assets - Liabilities Profile
            </h3>
            <div className='row'>
              <div className='col-md-4'>
                <div className='col-md-10 pl-4'>
                  <h5>Dependents in Family</h5>
                  <div className='text-black'>
                    Number of Dependents
                    <input
                      disabled={true}
                      placeholder='Count'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.dependentsInFamily?.dependents
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            dependentsInFamily: {
                              ...assetsLiabilitiesProfile?.dependentsInFamily,
                              dependents: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <div className='limit-parameters'>
                      Children, Parents or Family Members
                    </div>
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Children
                    <input
                      placeholder='Count'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.dependentsInFamily?.children
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            dependentsInFamily: {
                              ...assetsLiabilitiesProfile?.dependentsInFamily,
                              children: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Parents
                    <input
                      placeholder='Count'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.dependentsInFamily?.parents
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            dependentsInFamily: {
                              ...assetsLiabilitiesProfile?.dependentsInFamily,
                              parents: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Joint Family
                    <input
                      placeholder='Count'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.dependentsInFamily
                          ?.jointFamily
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            dependentsInFamily: {
                              ...assetsLiabilitiesProfile?.dependentsInFamily,
                              jointFamily: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Yearly fees paid for children
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.dependentsInFamily
                          ?.yearlyFeesPaid
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            dependentsInFamily: {
                              ...assetsLiabilitiesProfile?.dependentsInFamily,
                              yearlyFeesPaid: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <h5 className='mt-5 font-weight-bold pl-4'>
                  Total Insurance Premium
                </h5>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Total Amount in Last 12 months
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        Number(
                          assetsLiabilitiesProfile?.totalInsurancePremium
                            ?.lifeInsurance
                        ) +
                        Number(
                          assetsLiabilitiesProfile?.totalInsurancePremium
                            ?.termInsurance
                        ) +
                        Number(
                          assetsLiabilitiesProfile?.totalInsurancePremium
                            ?.healthInsurance
                        ) +
                        Number(
                          assetsLiabilitiesProfile?.totalInsurancePremium
                            ?.businessAmount
                        )
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            totalInsurancePremium: {
                              ...assetsLiabilitiesProfile?.totalInsurancePremium,
                              totalAmount: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <div className='limit-parameters'>
                      Sum of Term, Life, Health, Business amounts
                    </div>
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Term
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.totalInsurancePremium
                          ?.termInsurance
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            totalInsurancePremium: {
                              ...assetsLiabilitiesProfile?.totalInsurancePremium,
                              termInsurance: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Life
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.totalInsurancePremium
                          ?.lifeInsurance
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            totalInsurancePremium: {
                              ...assetsLiabilitiesProfile?.totalInsurancePremium,
                              lifeInsurance: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Health
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.totalInsurancePremium
                          ?.healthInsurance
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            totalInsurancePremium: {
                              ...assetsLiabilitiesProfile?.totalInsurancePremium,
                              healthInsurance: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Business
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.totalInsurancePremium
                          ?.businessAmount
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            totalInsurancePremium: {
                              ...assetsLiabilitiesProfile?.totalInsurancePremium,
                              businessAmount: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='col-md-10 pl-4'>
                  <h5>Asset owned value</h5>
                  <div className='text-black'>
                    Asset owned value
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        Number(
                          assetsLiabilitiesProfile?.assetOwnedValue
                            ?.totalTwoWheelerValue
                        ) +
                        Number(
                          assetsLiabilitiesProfile?.assetOwnedValue
                            ?.totalFourWheelervalue
                        ) +
                        Number(
                          assetsLiabilitiesProfile?.assetOwnedValue
                            ?.propertyValue
                        )
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            assetOwnedValue: {
                              ...assetsLiabilitiesProfile?.assetOwnedValue,
                              totalAssetValue: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <div className='limit-parameters'>
                      property, four wheeler, two wheeler
                    </div>
                  </div>
                  <div className='input_boxes'>
                    <input
                      disabled={true}
                      placeholder='Count'
                      type='number'
                      required
                      className='w-20 mr-3'
                      value={
                        assetsLiabilitiesProfile?.assetOwnedValue
                          ?.numberOfFourWheelers
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            assetOwnedValue: {
                              ...assetsLiabilitiesProfile?.assetOwnedValue,
                              numberOfFourWheelers: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <input
                      disabled={true}
                      placeholder='Four Wheeler Value'
                      type='number'
                      required
                      className='w-70'
                      value={
                        assetsLiabilitiesProfile?.assetOwnedValue
                          ?.totalFourWheelervalue
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            assetOwnedValue: {
                              ...assetsLiabilitiesProfile?.assetOwnedValue,
                              totalFourWheelervalue: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                  <div className='input_boxes'>
                    <input
                      disabled={true}
                      placeholder='Count'
                      type='number'
                      required
                      className='w-20 mr-3'
                      value={
                        assetsLiabilitiesProfile?.assetOwnedValue
                          ?.numberOfTwoWheelers
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            assetOwnedValue: {
                              ...assetsLiabilitiesProfile?.assetOwnedValue,
                              numberOfTwoWheelers: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <input
                      disabled={true}
                      placeholder='Two Wheeler'
                      type='number'
                      required
                      className='w-70'
                      value={
                        assetsLiabilitiesProfile?.assetOwnedValue
                          ?.totalTwoWheelerValue
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            assetOwnedValue: {
                              ...assetsLiabilitiesProfile?.assetOwnedValue,
                              totalTwoWheelerValue: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                  <div className='input_boxes'>
                    <input
                      placeholder='Count'
                      type='number'
                      required
                      className='w-20 mr-3'
                      value={
                        assetsLiabilitiesProfile?.assetOwnedValue
                          ?.numberOfProperties
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            assetOwnedValue: {
                              ...assetsLiabilitiesProfile?.totalInsurancePremium,
                              numberOfProperties: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <input
                      placeholder='Property'
                      type='number'
                      required
                      className='w-70'
                      value={
                        assetsLiabilitiesProfile?.assetOwnedValue?.propertyValue
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            assetOwnedValue: {
                              ...assetsLiabilitiesProfile?.assetOwnedValue,
                              propertyValue: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='col-md-10 pl-4'>
                  <h5>Total Savings amount</h5>
                  <div className='text-black'>
                    Amount
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        Number(
                          assetsLiabilitiesProfile?.savingsAmount
                            ?.chitFundsValue
                        ) +
                        Number(
                          assetsLiabilitiesProfile?.savingsAmount?.equityValue
                        ) +
                        Number(
                          assetsLiabilitiesProfile?.savingsAmount?.fdvalue
                        ) +
                        Number(
                          assetsLiabilitiesProfile?.savingsAmount?.goldValue
                        ) +
                        Number(
                          assetsLiabilitiesProfile?.savingsAmount?.otherValue
                        )
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            savingsAmount: {
                              ...assetsLiabilitiesProfile?.savingsAmount,
                              savingAmount: e.target.value,
                            },
                          },
                        })
                      }
                    />
                    <div className='limit-parameters'>
                      Sum of FD, Gold , Chit Funds , Equity , Other amounts
                    </div>
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    FD
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={assetsLiabilitiesProfile?.savingsAmount?.fdvalue}
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            savingsAmount: {
                              ...assetsLiabilitiesProfile?.savingsAmount,
                              fdvalue: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Gold
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={assetsLiabilitiesProfile?.savingsAmount?.goldValue}
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            savingsAmount: {
                              ...assetsLiabilitiesProfile?.savingsAmount,
                              goldValue: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Chit Funds
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.savingsAmount?.chitFundsValue
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            savingsAmount: {
                              ...assetsLiabilitiesProfile?.savingsAmount,
                              chitFundsValue: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Equity
                    <input
                      disabled={true}
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.savingsAmount?.equityValue
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            savingsAmount: {
                              ...assetsLiabilitiesProfile?.savingsAmount,
                              equityValue: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='col-md-10 pl-4'>
                  <div className='text-black'>
                    Other
                    <input
                      placeholder='₹ Amount'
                      type='number'
                      required
                      value={
                        assetsLiabilitiesProfile?.savingsAmount?.otherValue
                      }
                      onChange={(e) =>
                        this.setState({
                          assetsLiabilitiesProfile: {
                            ...assetsLiabilitiesProfile,
                            savingsAmount: {
                              ...assetsLiabilitiesProfile?.savingsAmount,
                              otherValue: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary m-heading '>
              Stability Profile
            </h3>
            <div className='row'>
              <div className='col-md-6'>
                <div className='col-md-7 pl-4'>
                  <div className='text-black'>
                    Resendential House
                    <div className='col-md-12 d-flex pl-0'>
                      <input
                        value='OWNED'
                        name='resendential_space'
                        type='radio'
                        onChange={(e) => this.spaceHandler(e, 0, 'space')}
                        checked={stabilityProfile?.[0]?.space === 'OWNED'}
                      />
                      <div>Owned space</div>
                      <input
                        value='RENTED'
                        className='ml-5'
                        name='resendential_space'
                        type='radio'
                        onChange={(e) => this.spaceHandler(e, 0, 'space')}
                        checked={stabilityProfile?.[0]?.space === 'RENTED'}
                      />
                      <div>Rented space</div>
                    </div>
                  </div>
                </div>

                {stabilityProfile?.[0]?.space === 'OWNED' ? (
                  <>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Duration of same house
                        <input
                          disabled={true}
                          placeholder='Count'
                          type='number'
                          required
                          name={'duration'}
                          value={stabilityProfile?.[0]?.duration}
                          onChange={(e) => this.stabilityProfileHandler(e, 0)}
                        />
                      </div>
                    </div>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Purchase Amount
                        <input
                          placeholder='₹ Amount'
                          type='number'
                          required
                          name={'purchaseAmount'}
                          value={stabilityProfile?.[0]?.purchaseAmount}
                          onChange={(e) => this.stabilityProfileHandler(e, 0)}
                        />
                      </div>
                    </div>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Ownership type
                        <select
                          onChange={(e) => this.stabilityProfileHandler(e, 0)}
                          value={stabilityProfile?.[0]?.ownershipType}
                          name='ownershipType'
                        >
                          <option value=''>Select Ownership type</option>
                          <option value='SELF'>Self purchased </option>
                          <option value='INHERITED'> Inherited</option>
                        </select>
                        <div className='limit-parameters'>
                          Self purchased or Inherited
                        </div>
                      </div>
                    </div>

                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Year of Purchase
                        <input
                          disabled={true}
                          placeholder='Year of Purchase'
                          type='number'
                          required
                          name={'yearOfPurchase'}
                          value={stabilityProfile?.[0]?.yearOfPurchase}
                          onChange={(e) => this.stabilityProfileHandler(e, 0)}
                        />
                      </div>
                    </div>
                  </>
                ) : stabilityProfile?.[0]?.space === 'RENTED' ? (
                  <>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Period Of Stay
                        <input
                          disabled={true}
                          placeholder='Count in years'
                          type='number'
                          required
                          name={'periodOfStay'}
                          value={stabilityProfile?.[0]?.periodOfStay}
                          onChange={(e) => this.stabilityProfileHandler(e, 0)}
                        />
                      </div>
                    </div>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Monthly Rent
                        <input
                          disabled={true}
                          placeholder='₹ Amount'
                          type='number'
                          required
                          name={'monthlyRent'}
                          value={stabilityProfile?.[0]?.monthlyRent}
                          onChange={(e) => this.stabilityProfileHandler(e, 0)}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div className='col-md-6'>
                <div className='col-md-7 pl-4'>
                  <div className='text-black'>
                    Business Premises
                    <div className='col-md-12 d-flex pl-0'>
                      <input
                        value='OWNED'
                        name='business_space'
                        type='radio'
                        onChange={(e) => this.spaceHandler(e, 1, 'space')}
                        checked={stabilityProfile?.[1]?.space === 'OWNED'}
                      />
                      <div>Owned space</div>
                      <input
                        className='ml-5'
                        value={'RENTED'}
                        name='business_space'
                        type='radio'
                        onChange={(e) => this.spaceHandler(e, 1, 'space')}
                        checked={stabilityProfile?.[1]?.space === 'RENTED'}
                      />
                      <div>Rented space</div>
                    </div>
                    <div className='limit-parameters'>
                      Long Lease of 30 years is considered owned
                    </div>
                  </div>
                </div>

                {stabilityProfile?.[1]?.space === 'OWNED' ? (
                  <>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Duration of same Premises
                        <input
                          disabled={true}
                          placeholder='Count'
                          type='number'
                          required
                          name={'duration'}
                          value={stabilityProfile?.[1]?.duration}
                          onChange={(e) => this.stabilityProfileHandler(e, 1)}
                        />
                      </div>
                    </div>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Purchase Amount
                        <input
                          disabled={true}
                          placeholder='₹ Amount'
                          type='number'
                          required
                          name={'purchaseAmount'}
                          value={stabilityProfile?.[1]?.purchaseAmount}
                          onChange={(e) => this.stabilityProfileHandler(e, 1)}
                        />
                      </div>
                    </div>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Ownership type
                        <select
                          onChange={(e) => this.stabilityProfileHandler(e, 1)}
                          value={stabilityProfile?.[1]?.ownershipType}
                          name='ownershipType'
                        >
                          <option value=''>Select Ownership type</option>
                          <option value='SELF'>Self purchased </option>
                          <option value='INHERITED'> Inherited</option>
                        </select>
                        <div className='limit-parameters'>
                          Self purchased or Inherited
                        </div>
                      </div>
                    </div>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Year of Purchase
                        <input
                          disabled={true}
                          placeholder='Year of Purchase'
                          name={'yearOfPurchase'}
                          type='number'
                          required
                          value={stabilityProfile?.[1]?.yearOfPurchase}
                          onChange={(e) => this.stabilityProfileHandler(e, 1)}
                        />
                      </div>
                    </div>
                  </>
                ) : stabilityProfile?.[1]?.space === 'RENTED' ? (
                  <>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Period Of Stay
                        <input
                          disabled={true}
                          placeholder='Count in years'
                          type='number'
                          required
                          name={'periodOfStay'}
                          value={stabilityProfile?.[1]?.periodOfStay}
                          onChange={(e) => this.stabilityProfileHandler(e, 1)}
                        />
                      </div>
                    </div>
                    <div className='col-md-7 pl-4'>
                      <div className='text-black'>
                        Monthly Rent
                        <input
                          disabled={true}
                          placeholder='₹ Amount'
                          type='number'
                          required
                          name={'monthlyRent'}
                          value={stabilityProfile?.[1]?.monthlyRent}
                          onChange={(e) => this.stabilityProfileHandler(e, 1)}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary m-heading '>
              Limit Eligibility
            </h3>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Limit Amount Required
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={limitInfo?.requiredAmount}
                    onChange={(e) =>
                      this.setState({
                        limitInfo: {
                          ...limitInfo,
                          requiredAmount: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-2'>
                <div className='text-black'>
                  Subvention
                  <div className='col-md-12 d-flex '>
                    <input
                      name='subvention'
                      type='radio'
                      onChange={(e) =>
                        this.setState({
                          limitInfo: {
                            ...limitInfo,
                            subvented: true,
                          },
                        })
                      }
                      checked={limitInfo?.subvented === true}
                    />
                    <div>Yes</div>
                  </div>
                  <div className='col-md-12 d-flex '>
                    <input
                      name='subvention'
                      type='radio'
                      onChange={(e) =>
                        this.setState({
                          limitInfo: {
                            ...limitInfo,
                            subvented: false,
                          },
                        })
                      }
                      checked={limitInfo?.subvented === false}
                    />
                    <div>No</div>
                  </div>
                </div>
              </div>
              <div className='col-md-3 '>
                <div className='text-black'>
                  Limit Amount Eligible
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={limitInfo?.eligibleAmount}
                    onChange={(e) =>
                      this.setState({
                        limitInfo: {
                          ...limitInfo,
                          eligibleAmount: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary m-heading'>
              Credit Behavior
            </h3>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Bureau Definition
                  <select
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          bureauDefinition: e.target.value,
                        },
                      })
                    }
                    value={creditBehaviour?.bureauDefinition}
                  >
                    <option value=''>Select Bureau Definition</option>
                    <option value='THICK'>Thick</option>
                    <option value='THIN'>Thin</option>
                  </select>
                  <div className='limit-parameters'>Thick , Thin or NTC</div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Mobile Number Match
                  <div className='col-md-12 d-flex '>
                    <input
                      name='mobile_match'
                      type='radio'
                      onChange={(e) =>
                        this.setState({
                          creditBehaviour: {
                            ...creditBehaviour,
                            mobileNumberMatched: true,
                          },
                        })
                      }
                      checked={creditBehaviour?.mobileNumberMatched === true}
                    />
                    <div>Yes</div>
                  </div>
                  <div className='col-md-12 d-flex '>
                    <input
                      name='mobile_match'
                      type='radio'
                      onChange={(e) =>
                        this.setState({
                          creditBehaviour: {
                            ...creditBehaviour,
                            mobileNumberMatched: false,
                          },
                        })
                      }
                      checked={creditBehaviour?.mobileNumberMatched === false}
                    />
                    <div>No</div>
                  </div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Bureau Score
                  <input
                    placeholder='Number'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.bureauScore
                        ? creditBehaviour?.bureauScore ?? 'NA'
                        : creditBehaviour?.bureauScore
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          bureauScore: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>In Last 12 months</div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  No. of Live Loans
                  <input
                    placeholder='Number'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.numberOfLiveLoans
                        ? creditBehaviour?.numberOfLiveLoans ?? 'NA'
                        : creditBehaviour?.numberOfLiveLoans
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          numberOfLiveLoans: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>
                    (Other Than GL, TW, CD)
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Last Month Ageing
                  <input
                    placeholder='Count'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.lastMonthAgeing
                        ? creditBehaviour?.lastMonthAgeing ?? 'NA'
                        : creditBehaviour?.lastMonthAgeing
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          lastMonthAgeing: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Peak Ageing
                  <input
                    placeholder='Count'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.peakAgeing
                        ? creditBehaviour?.peakAgeing ?? 'NA'
                        : creditBehaviour?.peakAgeing
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          peakAgeing: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>in Last 6 Months</div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Write OFF Amount
                  <input
                    placeholder='₹ Amount'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.writeOffAmount
                        ? creditBehaviour?.writeOffAmount ?? 'NA'
                        : creditBehaviour?.writeOffAmount
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          writeOffAmount: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Settled Amount
                  <input
                    placeholder='₹ Amount'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.settledAmount
                        ? creditBehaviour?.settledAmount ?? 'NA'
                        : creditBehaviour?.settledAmount
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          settledAmount: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  No. of EMI Bouncing in 3 months
                  <input
                    placeholder='Count'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit &&
                      creditBehaviour?.numberOfEmiBouncingIn3Months
                        ? creditBehaviour?.numberOfEmiBouncingIn3Months ?? 'NA'
                        : creditBehaviour?.numberOfEmiBouncingIn3Months
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          numberOfEmiBouncingIn3Months: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  No. of EMI Bouncing in 6 months
                  <input
                    placeholder='Count'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit &&
                      creditBehaviour?.numberOfEmiBouncingIn6Months
                        ? creditBehaviour?.numberOfEmiBouncingIn6Months ?? 'NA'
                        : creditBehaviour?.numberOfEmiBouncingIn6Months
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          numberOfEmiBouncingIn6Months: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>

              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  DPD in last 6 Months &gt; 30
                  <input
                    placeholder='Count'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit &&
                      creditBehaviour?.thirtyPlusDPDWithin6Months
                        ? creditBehaviour?.thirtyPlusDPDWithin6Months ?? 'NA'
                        : creditBehaviour?.thirtyPlusDPDWithin6Months
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          thirtyPlusDPDWithin6Months: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  DPD in last 12 Months &gt; 60
                  <input
                    placeholder='Count'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit &&
                      creditBehaviour?.sixtyPlusDPDWithin12Months
                        ? creditBehaviour?.sixtyPlusDPDWithin12Months ?? 'NA'
                        : creditBehaviour?.sixtyPlusDPDWithin12Months
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          sixtyPlusDPDWithin12Months: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  DPD in last 18 Months &gt; 90
                  <input
                    placeholder='Count'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit &&
                      creditBehaviour?.ninetyPlusDPDWithin18Months
                        ? creditBehaviour?.ninetyPlusDPDWithin18Months ?? 'NA'
                        : creditBehaviour?.ninetyPlusDPDWithin18Months
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          ninetyPlusDPDWithin18Months: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  SMA Or DoubtFul Or Substandard
                  <input
                    placeholder='Count'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit &&
                      creditBehaviour?.smaOrDoubtFulOrSubstandard
                        ? creditBehaviour?.smaOrDoubtFulOrSubstandard ?? 'NA'
                        : creditBehaviour?.smaOrDoubtFulOrSubstandard
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          smaOrDoubtFulOrSubstandard: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  WriteOf Or Restructuring Or Settled
                  <input
                    placeholder='Count'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit &&
                      creditBehaviour?.writeOfOrRestructuringOrSettled
                        ? creditBehaviour?.writeOfOrRestructuringOrSettled ??
                          'NA'
                        : creditBehaviour?.writeOfOrRestructuringOrSettled
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          writeOfOrRestructuringOrSettled: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Total Outstanding Loan
                  <input
                    placeholder='₹ Amount'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit &&
                      creditBehaviour?.totalOutstandingLoan
                        ? creditBehaviour?.totalOutstandingLoan ?? 'NA'
                        : creditBehaviour?.totalOutstandingLoan
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          totalOutstandingLoan: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className='limit-parameters'>From CIBIL</div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  EMI per month
                  <input
                    placeholder='₹ Amount'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.emiPerMonth
                        ? creditBehaviour?.emiPerMonth ?? 'NA'
                        : creditBehaviour?.emiPerMonth
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          emiPerMonth: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>From Banking</div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Defaulted Loans
                  <input
                    placeholder='₹ Amount'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.defaultedLoans
                        ? creditBehaviour?.defaultedLoans ?? 'NA'
                        : creditBehaviour?.defaultedLoans
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          defaultedLoans: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>
                    Default in loans in last 2 years
                  </div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Unsecured Loans
                  <input
                    placeholder='Number'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.unsecuredLoans
                        ? creditBehaviour?.unsecuredLoans ?? 'NA'
                        : creditBehaviour?.unsecuredLoans
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          unsecuredLoans: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>In days</div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Imputed EMI
                  <input
                    placeholder='Number'
                    type='tel'
                    required
                    value={
                      creditBehaviourExit && creditBehaviour?.imputedEmi
                        ? creditBehaviour?.imputedEmi ?? 'NA'
                        : creditBehaviour?.imputedEmi
                    }
                    onChange={(e) =>
                      this.setState({
                        creditBehaviour: {
                          ...creditBehaviour,
                          imputedEmi: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>In days</div>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary m-heading '>
              Turnover Analysis
            </h3>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Customer GST Turnover
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={turnoverAnalysis?.customerGstTurnover}
                    onChange={(e) =>
                      this.setState({
                        turnoverAnalysis: {
                          ...turnoverAnalysis,
                          customerGstTurnover: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Turnover As per Banking
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={turnoverAnalysis?.turnoverAsPerBanking}
                    onChange={(e) =>
                      this.setState({
                        turnoverAnalysis: {
                          ...turnoverAnalysis,
                          turnoverAsPerBanking: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Turnover to be considered
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={turnoverAnalysis?.turnoverToBeConsidered}
                    onChange={(e) =>
                      this.setState({
                        turnoverAnalysis: {
                          ...turnoverAnalysis,
                          turnoverToBeConsidered: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  % Devaition from Customer Declaration
                  <input
                    placeholder='Percentage(%)'
                    type='number'
                    required
                    value={turnoverAnalysis?.percentageDeviation}
                    onChange={(e) =>
                      this.setState({
                        turnoverAnalysis: {
                          ...turnoverAnalysis,
                          percentageDeviation: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Turnover Computed as per Bureau
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={turnoverAnalysis?.turnoverComputedAsPerBureau}
                    onChange={(e) =>
                      this.setState({
                        turnoverAnalysis: {
                          ...turnoverAnalysis,
                          turnoverComputedAsPerBureau: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  % of GST Turnover
                  <input
                    placeholder='Percentage(%)'
                    type='number'
                    required
                    value={turnoverAnalysis?.percentageGstTurnOver}
                    onChange={(e) =>
                      this.setState({
                        turnoverAnalysis: {
                          ...turnoverAnalysis,
                          percentageGstTurnOver: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Banking ABB
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={turnoverAnalysis?.bankingAbb}
                    onChange={(e) =>
                      this.setState({
                        turnoverAnalysis: {
                          ...turnoverAnalysis,
                          bankingAbb: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Min Balance instances
                  <input
                    placeholder='Count'
                    type='number'
                    required
                    value={turnoverAnalysis?.minBalanceInstances}
                    onChange={(e) =>
                      this.setState({
                        turnoverAnalysis: {
                          ...turnoverAnalysis,
                          minBalanceInstances: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>In Last 12 months</div>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary m-heading '>
              Seller Relationship
            </h3>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Avg Monthly Purchase in last 12 Months
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={sellerRelationShip?.averageMonthlyPurchase}
                    onChange={(e) =>
                      this.setState({
                        sellerRelationShip: {
                          ...sellerRelationShip,
                          averageMonthlyPurchase: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  No. of Orders in a Year
                  <input
                    placeholder='Count'
                    type='number'
                    required
                    value={sellerRelationShip?.numberOfOrdersInAYear}
                    onChange={(e) =>
                      this.setState({
                        sellerRelationShip: {
                          ...sellerRelationShip,
                          numberOfOrdersInAYear: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  No. of Months Relationship with Supplier
                  <input
                    placeholder='Count'
                    type='number'
                    required
                    value={sellerRelationShip?.numberOfMonthsRelationship}
                    onChange={(e) =>
                      this.setState({
                        sellerRelationShip: {
                          ...sellerRelationShip,
                          numberOfMonthsRelationship: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Avg Repayment time in Last 12 Months
                  <input
                    placeholder='Count'
                    type='number'
                    required
                    value={sellerRelationShip?.averageRepaymentTime}
                    onChange={(e) =>
                      this.setState({
                        sellerRelationShip: {
                          ...sellerRelationShip,
                          averageRepaymentTime: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  GST Total Purchase
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={sellerRelationShip?.gstTotalPurchase}
                    onChange={(e) =>
                      this.setState({
                        sellerRelationShip: {
                          ...sellerRelationShip,
                          gstTotalPurchase: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className='limit-parameters'>From Supplier GST</div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Total Purchase
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={sellerRelationShip?.totalPurchase}
                    onChange={(e) =>
                      this.setState({
                        sellerRelationShip: {
                          ...sellerRelationShip,
                          totalPurchase: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>From Supplier Data</div>
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Purchase amount considered
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={sellerRelationShip?.purchaseAmountConsidered}
                    onChange={(e) =>
                      this.setState({
                        sellerRelationShip: {
                          ...sellerRelationShip,
                          purchaseAmountConsidered: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Purchse as per banking with supplier
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={sellerRelationShip?.purchaseAsPerBankingWithSupplier}
                    onChange={(e) =>
                      this.setState({
                        sellerRelationShip: {
                          ...sellerRelationShip,
                          purchaseAsPerBankingWithSupplier: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  GST Total Purchase for Associated Supplier
                  <input
                    placeholder='₹ Amount'
                    type='number'
                    required
                    value={
                      sellerRelationShip?.gstTotalPurchaseForAssociatedSupplier
                    }
                    onChange={(e) =>
                      this.setState({
                        sellerRelationShip: {
                          ...sellerRelationShip,
                          gstTotalPurchaseForAssociatedSupplier: e.target.value,
                        },
                      })
                    }
                  />
                  <div className='limit-parameters'>From Customer GST</div>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <h3 className=' card_heading_secondary  m-heading '>Remarks</h3>
            <div className='row'>
              <div className='col-md-3 pl-4'>
                <div className='text-black'>
                  Remarks
                  <input
                    placeholder='Remarks here'
                    type='text'
                    required
                    value={remarks}
                    onChange={(e) =>
                      this.setState({
                        remarks: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {!submitted ? (
            <div className='button_box mb-5'>
              <button
                onClick={(e) => this.submitCam(e, false)}
                className='button save_button'
              >
                Save
              </button>
              <button type='submit' className='button submit_button'>
                Submit
              </button>
            </div>
          ) : null}
        </form>
      </div>
    );
  }

  componentDidMount = async () => {
    let { actions, match } = this.props,
      { userId } = match.params,
      { loaderModal } = actions;
    loaderModal(true);

    await GET(API_GET_CAM_DATA(userId)).then(({ data }) => {
      if (data.data) {
        let {
          sellerRelationShip,
          turnoverAnalysis,
          creditBehaviour,
          limitInfo,
          stabilityProfile,
          assetsLiabilitiesProfile,
          neighbourReference,
          supplierReference,
          businessDetails,
          remarks,
          customerType,
          promoterAge,
          dedupeResult,
          submitted,
        } = data.data;
        this.setState({
          sellerRelationShip,
          turnoverAnalysis,
          creditBehaviour,
          limitInfo,
          stabilityProfile,
          assetsLiabilitiesProfile,
          neighbourReference,
          supplierReference,
          businessDetails,
          remarks,
          customerType,
          promoterAge,
          dedupeResult,
          submitted,
        });
      }
    });

    loaderModal(false);
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(Cam);
